<template class="mr-3">
  <div class="card">
    <div class="card-body">
      <div>
        <p>{{ $t("all.filterBy") }}:</p>
        <a-select
          id="selectMonth"
          :placeholder="$t('recette.tousMois')"
          style="width: 200px; margin-left: 15px"
          option-filter-prop="children"
          show-search
          :filter-option="filterOption"
          @change="handleChangeFilter"
          default-value="all"
        >
          <a-select-option value="all">
            {{ $t("recette.tousMois") }}
          </a-select-option>
          <a-select-option value="01"> {{ moment.months(0) }} </a-select-option>
          <a-select-option value="02"> {{ moment.months(1) }} </a-select-option>
          <a-select-option value="03"> {{ moment.months(2) }} </a-select-option>
          <a-select-option value="04"> {{ moment.months(3) }} </a-select-option>
          <a-select-option value="05"> {{ moment.months(4) }} </a-select-option>
          <a-select-option value="06"> {{ moment.months(5) }} </a-select-option>
          <a-select-option value="07"> {{ moment.months(6) }} </a-select-option>
          <a-select-option value="08"> {{ moment.months(7) }} </a-select-option>
          <a-select-option value="09"> {{ moment.months(8) }} </a-select-option>
          <a-select-option value="10">
            {{ moment.months(9) }}
          </a-select-option>
          <a-select-option value="11">
            {{ moment.months(10) }}
          </a-select-option>
          <a-select-option value="12">
            {{ moment.months(11) }}
          </a-select-option>
        </a-select>

        <a-button
          type="file-pdf"
          @click="generatePdf"
          style="margin-bottom: 20px; float: right"
        >
          <a-icon type="file-pdf" @click="generatePdf" />
          {{ $t("action.imprimerTab") }}
        </a-button>
        <json-csv
          class="ant-btn"
          style="margin-right: 20px; float: right"
          :data="csvToExport"
          :name="'chiffre daffaire_scolarité.csv'"
          ><a-icon
            class="anticon mt-2"
            style="color: green"
            type="file-excel"
          /><span>CSV</span></json-csv
        >
      </div>
      <div>
        <a-table
          :loading="tableLoading"
          @change="tableChanged"
          rowKey="_id"
          :pagination="true"
          :data-source="data"
          :columns="columns"
        >
          <template slot="parent" slot-scope="text, record">
            {{ record.parent.fatherName }} {{ $t("all.and") }}
            {{ record.parent.motherName }}
          </template>
          <template slot="expandedRowRender" slot-scope="record">
            <div style="margin: 0">
              <table class="table table-striped">
                <thead>
                  <th>{{ $t("recette.mois") }}</th>
                  <th>{{ $t("recette.montant") }}</th>
                  <th>{{ $t("topBar.status") }}</th>
                </thead>
                <tbody>
                  <tr v-if="record.dataType.inscription && mF">
                    <td>{{ $t("recette.inscription") }}</td>
                    <td v-if="!record.inscription">
                      <del v-if="record.repReduction.inscription > 0">
                        {{ record.dataType.inscription }}
                      </del>
                      <div v-else>
                        {{ record.dataType.inscription }}
                      </div>
                      <sup
                        v-if="record.repReduction.inscription > 0"
                        class="text-success"
                        >-{{ record.repReduction.inscription }}%</sup
                      >
                      <div v-if="record.repReduction.inscription > 0">
                        ={{ record.monthsMoney.inscription }}
                      </div>
                    </td>
                    <td v-else>
                      <div>{{ record.monthsMoney.inscription }}</div>
                    </td>
                    <td>
                      {{
                        record.inscription
                          ? $t("paiement.paye")
                          : $t("paiement.impaye")
                      }}
                    </td>
                  </tr>
                  <tr v-for="(month, key) in record.months" :key="key">
                    <td>{{ getMonthName(key) }} ({{ key }})</td>
                    <td>
                      <div v-if="!record.months[key]">
                        <del v-if="record.repReduction[key] > 0">
                          {{ record.dataType.monthly }}
                        </del>
                        <div v-else>{{ record.dataType.monthly }}</div>
                        <sup
                          v-if="record.repReduction[key] > 0"
                          class="text-success"
                          >-{{ record.repReduction[key] }}%</sup
                        >
                        <div v-if="record.repReduction[key] > 0">
                          ={{ record.monthsMoney[key] }}
                        </div>
                      </div>
                      <div v-else>
                        <div>{{ record.monthsMoney[key] }}</div>
                      </div>
                    </td>
                    <td>
                      {{
                        record.months[key]
                          ? $t("paiement.paye")
                          : $t("paiement.impaye")
                      }}
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </template>
          <template slot="status" slot-scope="text">
            {{ text == "payed" ? $t("all.oui") : $t("all.non") }}
          </template>
          <template slot="phone" slot-scope="text, record">
            {{ record.parent.fatherPhone }}
          </template>
          <template slot="student" slot-scope="text, record">
            {{ record.student.firstName }} {{ record.student.lastName }}
          </template>
          <template slot="type" slot-scope="text, record">
            <div>
              {{
                record.typeAS == "A"
                  ? $t("paiement.activite") + ": "
                  : $t("menu.scolarite") + ": "
              }}{{ record.name }}
            </div>
          </template>
          <div
            slot="filterDropdown"
            slot-scope="{
              setSelectedKeys,
              selectedKeys,
              confirm,
              clearFilters,
              column,
            }"
            style="padding: 8px"
          >
            <a-input
              v-ant-ref="(c) => (searchInput = c)"
              :placeholder="`${$t('personnel.chercher')} ${column.title}`"
              :value="selectedKeys[0]"
              style="width: 188px; margin-bottom: 8px; display: block"
              @change="
                (e) => setSelectedKeys(e.target.value ? [e.target.value] : [])
              "
              @pressEnter="
                () => handleSearch(selectedKeys, confirm, column.dataIndex)
              "
            />
            <a-button
              type="primary"
              icon="search"
              size="small"
              style="width: 90px; margin-right: 8px"
              @click="
                () => handleSearch(selectedKeys, confirm, column.dataIndex)
              "
              >{{ $t("paiement.chercher") }}</a-button
            >
            <a-button
              size="small"
              style="width: 90px"
              @click="() => handleReset(clearFilters)"
              >{{ $t("action.reinitialiser") }}</a-button
            >
          </div>
          <a-icon
            slot="filterIcon"
            slot-scope="filtered"
            type="search"
            :style="{ color: filtered ? '#108ee9' : undefined }"
          />
          <template
            slot="customRender"
            slot-scope="text, record, index, column"
          >
            <span v-if="searchText && searchedColumn === column.dataIndex">
              <template
                v-for="(fragment, i) in text
                  .toString()
                  .split(
                    new RegExp(`(?<=${searchText})|(?=${searchText})`, 'i')
                  )"
              >
                <mark
                  v-if="fragment.toLowerCase() === searchText.toLowerCase()"
                  :key="i"
                  class="highlight"
                  >{{ fragment }}</mark
                >
                <template v-else>{{ fragment }}</template>
              </template>
            </span>
            <template v-else>{{ text }}</template>
          </template>
          <template slot="delete" slot-scope="text, record">
            <a-popconfirm
              :title="$t('all.sureToDelete')"
              @confirm="() => onDelete(record.key)"
            >
              <a-button type="danger">
                <a-icon type="delete" href="javascript:;" />{{
                  $t("action.supprimer")
                }}
              </a-button>
            </a-popconfirm>
          </template>
        </a-table>
      </div>
    </div>
  </div>
</template>

<script>
/* eslint-disable */
import apiClient from "@/services/axios";
import JsPDF from "jspdf";
import "jspdf-autotable";
import writtenNumber from "written-number";
import { mapState } from "vuex";
import JsonCsv from "vue-json-csv";
import moment from "moment";

const getMonthName = (monthString) => {
  const months = {};
  const monthsArr = moment.months();
  for (let i = 0; i < monthsArr.length; i++) months[i + 1] = monthsArr[i];
  return monthString ? months[parseInt(monthString.split("-")[1])] : null;
};
export default {
  components: {
    JsonCsv,
  },
  async created() {
    await apiClient
      .get("/payment/chiffreAffaire/scolarite")
      .then((res) => {
        this.data = res.data;
        this.rowData = [...this.data];
      })
      .catch((err) => {
        console.log(err);
      });
    this.tableLoading = false;
    this.cacheData = this.data.map((item) => ({ ...item }));
    this.filtredTable = this.data;
  },
  computed: {
    ...mapState(["settings"]),
    csvToExport() {
      const data = [...this.filtredTable];
      return data.map((item) => {
        return {
          nomClient: `${item.parent.fatherName} ${item.parent.motherName}`,
          Payé: item.status == "payed" ? "oui" : "non",
          enfant: `${item.student.firstName} ${item.student.lastName}`,
          Frais:
            item.typeAS == "A"
              ? `${item.dataType.designation} : ${item.name}`
              : ` Scolarité : ${item.dataType.name}`,
          montantTotale: item.money,
          montantPayé: item.totalPayed,
          montantNonPaye: item.totalUnpayed,
        };
      });
    },
  },
  data() {
    return {
      mF: true,
      moment,
      rowData: [],
      data: [],
      searchText: "",
      searchInput: null,
      searchedColumn: "",
      value: 1,
      loadingPdf: false,
      filtredTable: [],
      tableLoading: true,
      columns: [
        {
          title: this.$t("recette.nom"),
          dataIndex: "parent",
          key: "parent",
          width: "150px",
          scopedSlots: {
            filterDropdown: "filterDropdown",
            filterIcon: "filterIcon",
            customRender: "parent",
          },
          onFilter: (value, record) => {
            let clientName =
              record.parent.fatherName + " ET " + record.parent.motherName;
            return clientName.toLowerCase().includes(value.toLowerCase());
          },
          onFilterDropdownVisibleChange: (visible) => {
            if (visible) {
              setTimeout(() => {
                this.searchInput.focus();
              });
            }
          },
        },
        {
          title: this.$t("recette.payeQues"),
          dataIndex: "status",
          key: "status",
          width: "150px",
          scopedSlots: {
            filterDropdown: "filterDropdown",
            filterIcon: "filterIcon",
            customRender: "status",
          },
          onFilter: (value, record) =>
            (record.status == "payed" ? "oui" : "non") == value.toLowerCase(),
          onFilterDropdownVisibleChange: (visible) => {
            if (visible) {
              setTimeout(() => {
                this.searchInput.focus();
              });
            }
          },
        },
        {
          title: this.$t("recette.enfant"),
          dataIndex: "student",
          key: "student",
          width: "150px",
          scopedSlots: {
            filterDropdown: "filterDropdown",
            filterIcon: "filterIcon",
            customRender: "student",
          },
          onFilter: (value, record) => {
            let studentName =
              record.student.firstName + " " + record.student.lastName;
            return studentName.toLowerCase().includes(value.toLowerCase());
          },
          onFilterDropdownVisibleChange: (visible) => {
            if (visible) {
              setTimeout(() => {
                this.searchInput.focus();
              });
            }
          },
        },
        {
          title: this.$t("recette.frais"),
          dataIndex: "type",
          key: "type",
          width: "300px",
          scopedSlots: {
            filterDropdown: "filterDropdown",
            filterIcon: "filterIcon",
            customRender: "type",
          },
          onFilter: (value, record) => {
            const testString =
              record.typeAS == "A"
                ? `Activité / ${record.service}: ${record.name}`
                : `Scolarité / ${record.name}`;
            return testString.toLowerCase().includes(value.toLowerCase());
          },
          onFilterDropdownVisibleChange: (visible) => {
            if (visible) {
              setTimeout(() => {
                this.searchInput.focus();
              });
            }
          },
        },
        {
          title: this.$t("recette.montantTotal"),
          dataIndex: "money",
          key: "money",
          width: "300px",
          scopedSlots: {
            filterDropdown: "filterDropdown",
            filterIcon: "filterIcon",
            customRender: "money",
          },
          onFilter: (value, record) =>
            (record.money + " ").toLowerCase().includes(value.toLowerCase()),
          onFilterDropdownVisibleChange: (visible) => {
            if (visible) {
              setTimeout(() => {
                this.searchInput.focus();
              });
            }
          },
        },
      ],
    };
  },
  methods: {
    getMonthName,
    tableChanged(pagination, filters, sorter, extra) {
      this.filtredTable = extra.currentDataSource;
    },
    filterOption(input, option) {
      return (
        option.componentOptions.children[0].text
          .toLowerCase()
          .indexOf(input.toLowerCase()) >= 0
      );
    },
    handleSearch(selectedKeys, confirm, dataIndex) {
      confirm();
      this.searchText = selectedKeys[0];
      this.searchedColumn = dataIndex;
    },
    handleReset(clearFilters) {
      clearFilters();
      this.searchText = "";
    },
    generatePdf() {
      this.$gtag.event("Imp chiffreAffaireScolarite", {
        event_category: "Impression PDF",
        event_label: "declarations:chiffreAffaireScolarite",
        value: 1,
      });

      let selectMonth = document.getElementById("selectMonth").innerText;
      let arrayOfImages = this.settings.image;
      for (let i = 0; i < arrayOfImages.length; i++) {
        if (this.settings.activeBuilding === arrayOfImages[i].db) {
          var imgData = new Image();
          imgData.src = arrayOfImages[i].logo;
        }
      }
      const doc = new JsPDF();
      doc.setFont("Amiri");
      const date = new Date();

      const titre = "Chiffre d'affaire - scolartié";
      const options = {
        weekday: "long",
        year: "numeric",
        month: "long",
        day: "numeric",
      };
      doc.setLanguage("ar");
      doc.setFontSize(15);
      doc.text(80, 60, titre);
      doc.text(92, 70, selectMonth);
      doc.setFontSize(10);
      try {
        doc.addImage(imgData, "JPEG", 8, 8, 40, 40);
      } catch (error) {
        console.log(error);
      }
      doc.setFontSize(12);
      doc.text(150, 40, date.toLocaleDateString("fr-FR", options));
      let arr = [];
      const array = [];
      let somme = 0;

      for (let i = 0; i < this.filtredTable.length; i++) {
        let currentIndex = 0;
        let monthsUnpaied = 0;
        if (this.filtredTable[i].months) {
          for (let key of Object.keys(this.filtredTable[i].months)) {
            monthsUnpaied++;
          }
        }
        if (currentIndex === 0) {
          currentIndex++;
          monthsUnpaied++;
          arr.unshift({
            rowSpan: monthsUnpaied,
            content:
              this.filtredTable[i].student.firstName +
              " " +
              this.filtredTable[i].student.lastName,
            styles: { valign: "middle" },
          });
          arr.unshift({
            rowSpan: monthsUnpaied,
            content: this.filtredTable[i].parent.fatherPhone,
            styles: { valign: "middle" },
          });
          arr.unshift({
            rowSpan: monthsUnpaied,
            content: this.filtredTable[i].parent.fatherName,
            styles: { valign: "middle" },
          });
        }
        if (!currentIndex) {
          arr.push(this.filtredTable[i].parent.fatherName);
          arr.push(this.filtredTable[i].parent.fatherPhone);
          arr.push(
            this.filtredTable[i].student.firstName +
              " " +
              this.filtredTable[i].student.lastName
          );
        }
        if (data[i].dataType.inscription) {
          arr.push("Inscription " + this.filtredTable[i].name);
          if (this.filtredTable[i].inscription == true) {
            arr.push({
              content: "payé",
              styles: { textColor: [14, 115, 31], fontStyle: "bold" },
            });
            arr.push(this.filtredTable[i].monthsMoney.inscription);
          } else {
            arr.push({
              content: "impayé",
              styles: { textColor: [209, 23, 45], fontStyle: "bold" },
            });
            arr.push(this.filtredTable[i].monthsMoney.inscription);
          }
          array.push(arr);
          arr = [];
        }

        if (this.filtredTable[i].months) {
          for (var key of Object.keys(this.filtredTable[i].months)) {
            let paymentDate = new Date(key);
            let monthName = paymentDate.toLocaleString("fr-FR", {
              month: "long",
            });
            arr.push(monthName + " - " + this.filtredTable[i].name);
            if (this.filtredTable[i].months[key] == true) {
              arr.push({
                content: "payé",
                styles: { textColor: [14, 115, 31], fontStyle: "bold" },
              });
              arr.push(this.filtredTable[i].monthsMoney[key]);
            } else {
              arr.push({
                content: "impayé",
                styles: { textColor: [209, 23, 45], fontStyle: "bold" },
              });
              arr.push(
                this.filtredTable[i].monthsMoney[key] //* (1 - this.filtredTable[i].defaultReduction / 100)
              );
            }

            //colspan
            array.push(arr);
            arr = [];
          }
        }
        somme += parseFloat(this.filtredTable[i].money);
      }
      doc.autoTable({
        theme: "grid",
        styles: { font: "Amiri" },
        startY: doc.pageCount > 1 ? doc.autoTableEndPosY() + 20 : 80,
        head: [
          ["Nom client", "Téléphone", "Enfant", "Frais", "Etat", "Montant"],
        ],
        body: array,
        foot: [
          [
            "Total",
            {
              colSpan: 4,
              content: writtenNumber(somme, { lang: "fr" }) + " DT",
              styles: { halign: "center" },
            },
            parseFloat(somme).toFixed(2),
          ],
        ],
      });
      //page numbering
      const pages = doc.internal.getNumberOfPages();
      const pageWidth = doc.internal.pageSize.width;
      const pageHeight = doc.internal.pageSize.height;
      doc.setFontSize(8); //Optional

      for (let j = 1; j < pages + 1; j++) {
        let horizontalPos = pageWidth / 2;
        let verticalPos = pageHeight - 10;
        doc.setPage(j);
        doc.text(`Page ${j} / ${pages}`, horizontalPos, verticalPos, {
          align: "center",
        });
      }
      //end page numbering
      doc.save("Chiffre d'affaire scolartié.pdf");
    },

    handleChangeFilter(filter) {
      const clone = this.rowData.slice();
      if (filter == "all") {
        this.data = [...clone];
        this.mF = true;
      } else {
        this.mF = false;
        let result = [];
        if (filter == "inscription")
          clone.forEach((elem) =>
            result.push({ ...elem, once: true, months: {} })
          );
        else {
          result = [...clone.filter((elem) => !elem.once)];
          result = result
            .filter((_) => _)
            .map((elem) => {
              elem = { ...elem, inscription: undefined };
              try {
                const monthKeys = Object.keys(elem.months).filter(
                  (e) => e.split("-")[1] == filter
                );
                if (monthKeys.length > 0) {
                  let months = {};
                  monthKeys.forEach((key) => {
                    months[key] = elem.months[key];
                  });
                  elem = {
                    ...elem,
                    months: months,
                  };
                } else elem = { ...elem, months: {} };
                return elem;
              } catch {
                return { ...elem, months: {}, inscription: undefined };
              }
            });
          result = result.filter(
            (elem) =>
              elem.inscription != undefined ||
              Object.keys(elem.months).length > 0
          );
        }
        this.data = [...this.data.filter((elem) => elem.money > 0)];
      }
      this.$forceUpdate();
      this.filtredTable = this.data;
    },
  },
};
</script>
